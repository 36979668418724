import React, { useState, useEffect, useRef } from 'react'

import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { styled, Button, Paper, useMediaQuery } from '@mui/material'
import classNames from 'classnames'

import { lockBodyScroll, unlockBodyScroll } from '@helpers/bodyscroll'
import { useTheme } from '@mui/material/styles'
import StrapiLink from '@templates/strapi-cms/content-types/Link'
import { useTest } from '@hooks/useTest'
import Megamenu from './Megamenu'

const getID = (value = '', suffix = '') => {
  const id = value.toLowerCase().replace(/\s/g, '-')
  return suffix ? `${id}-${suffix}` : id
}

const Popover = styled(Paper)`
  position: fixed;
  min-height: 570px;
  width: 1200px;
  border-radius: 0;
  border: solid 1px grey;
  background-color: ${p => p.theme.palette.background.paper};
  padding: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1200px) {
    left: 0 !important;
    transform: translateX(0);
    width: 100vw;
  }
`

const ListItem = styled('li')`
  padding: 0 5px;

  .nav-btn {
    padding: 12px;
    line-height: 1;
    font-size: 1rem;
    border-radius: 0;
    position: relative;
    display: block;
    font-weight: 600;
    color: ${p => p.theme.palette?.common?.black};
    text-decoration: none;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 4px;
      left: 0;
      transition: background-color 0.4s ease;
      background-color: transparent;
    }

    &.active {
      &::after {
        background-color: ${p => p.theme.palette?.warning?.main};
      }
    }

    &.open {
      &::after {
        background-color: ${p => p.theme.palette?.primary?.main};
      }
    }

    &:hover {
      color: ${p => p.theme.palette?.primary?.main};
      text-decoration: none;
    }
  }
`

const isActive = link => {
  if (link && typeof window !== 'undefined') {
    return window.location.href.includes(link?.InternalUrl)
  }
  return false
}

const NavigationItem = ({ data, onSetBackdrop, hoverOverDelay, setHoverOverDelay }) => {
  const theme = useTheme()
  const isMedium = useMediaQuery('(max-width: 1200px)')
  const [open, setOpen] = useState(false)
  const { generateTestId } = useTest()
  const hoverOverTimeoutRef = useRef()
  const hoverOverDelayTimeoutRef = useRef()

  const x = isMedium ? '0%' : '-50%'
  const contentID = data?.id
  const primaryLink = data?.PrimaryLink?.Link
  const hasChildren = !!data?.NavLinks?.length
  const menuID = getID(primaryLink?.DisplayText, 'megamenu')
  const buttonID = getID(primaryLink?.DisplayText, 'category-navigation-item-link')
  const fontColor = Object.is(primaryLink?.DisplayText.toLowerCase(), 'sales')
    ? theme.palette?.error?.main // red
    : theme.palette?.common?.black // black

  useEffect(
    () => () => {
      clearTimeout(hoverOverTimeoutRef.current)
      clearTimeout(hoverOverDelayTimeoutRef.current)
    },
    [],
  )

  const onClose = () => {
    if (open) {
      unlockBodyScroll()
      onSetBackdrop(false)
      setOpen(false)
    }
  }

  // When opening the first NavItem, we want a small delay in case the user didn't intend to open the meganav.
  // Once the user has one open, we want them to be able to open other NavItems with no delay.
  const onHoverOver = e => {
    clearTimeout(hoverOverDelayTimeoutRef.current)
    if (!hoverOverDelay) onHoverOpen()
    else {
      hoverOverTimeoutRef.current = setTimeout(() => {
        onHoverOpen()
      }, 300)
    }
  }

  // When the cursor leaves NavItem, we set hoverOverDelay to false so other NavItems will open immediately.
  // This lasts for 0.5s seconds, at which point we set hoverOverDelay back to true.
  // We don't do this if the mouseover didn't trigger the opening of the NavItem.
  const manageMouseLeaveHoverTimers = () => {
    clearTimeout(hoverOverTimeoutRef.current)
    clearTimeout(hoverOverDelayTimeoutRef.current)
    if (hoverOverDelay && open) {
      setHoverOverDelay(false)
    }
    hoverOverDelayTimeoutRef.current = setTimeout(() => {
      setHoverOverDelay(true)
    }, 500)
  }

  const onHoverOpen = () => {
    if (hasChildren) {
      lockBodyScroll()
      onSetBackdrop(true)
      setOpen(true)
      if (typeof window !== 'undefined') {
        window.dataLayer.push({
          category: 'navigation',
          action: 'open menu',
          label: primaryLink?.DisplayText,
        })
      }
    }
  }

  const onMouseLeave = e => {
    manageMouseLeaveHoverTimers()
    if (e.target.id) {
      if (e.target.id === buttonID) return

      if (e.target.id.includes('category-navigation-item-link')) {
        setOpen(false)
        return
      }
    }
    onClose()
  }

  const onKeyDown = e => {
    if (e.key === 'Escape' || (e.shiftKey && e.key === 'Tab')) {
      onClose()
    }
    if (e.code === 'Space' || e.key === 'Enter') {
      e.preventDefault()
      onHoverOpen()
    }
  }

  const buttonAriaProps = {
    'aria-haspopup': 'true',
    'aria-label': primaryLink?.DisplayText,
    ...(open
      ? {
          'aria-controls': menuID,
          'aria-expanded': 'true',
        }
      : {}),
  }

  return (
    <ListItem data-testid={generateTestId('navigation', 'main-category')}>
      <div id={buttonID} onMouseLeave={onMouseLeave}>
        <StrapiLink
          className={classNames('nav-btn', {
            open,
            active: isActive(primaryLink),
          })}
          onMouseEnter={onHoverOver}
          data-testid={primaryLink?.testId}
          role="button"
          data={primaryLink}
          {...buttonAriaProps}
          onKeyDown={onKeyDown}
          style={{ color: fontColor }}
          onClick={onClose}
        >
          {primaryLink?.DisplayText?.toUpperCase()}
        </StrapiLink>
        {hasChildren && (
          <Popover
            key="menuitem"
            component={motion.section}
            initial={{ display: 'none', opacity: 0, y: -60, x }}
            animate={{
              ...(open && { opacity: 1, display: 'block' }),
              y: 0,
              x,
            }}
            aria-labelledby={buttonID}
            id={menuID}
            open={open}
            onClose={onClose}
            onKeyDown={onKeyDown}
          >
            <Megamenu data={data} onClose={onClose} />
          </Popover>
        )}
      </div>
    </ListItem>
  )
}

NavigationItem.propTypes = {
  data: PropTypes.object.isRequired,
  onSetBackdrop: PropTypes.func.isRequired,
  hoverOverDelay: PropTypes.bool.isRequired,
  setHoverOverDelay: PropTypes.func.isRequired,
}

export default NavigationItem
