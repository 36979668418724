import { Box, styled } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import StrapiLink from './Link'

const SlideContainer = styled('div')(({ theme, variant, color }) => ({
  position: 'relative',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  [theme.breakpoints.down('md')]: {
    flexDirection: variant === 'IconOnTop' && 'column',
    gap: variant === 'IconOnTop' && '4px',
  },
  color,
}))

const InfoContainer = styled(Box)(({ theme, variant, hasIcon }) => ({
  display: 'flex',
  gap: '20px',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: variant === 'IconAdjacent' ? '70%' : '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: variant === 'IconAdjacent' && 'column',
    alignItems: variant === 'IconAdjacent' && hasIcon && 'flex-start',
    gap: variant === 'IconAdjacent' ? '0px' : '4px',
  },
}))

const StyledHeader = styled('h3')(({ theme, color }) => ({
  fontWeight: 600,
  fontSize: '16px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color,
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
}))

const SecondaryLinkComponent = styled(StrapiLink)(({ color, removeUnderline }) => ({
  height: '100%',
  fontWeight: 400,
  fontSize: '14px',
  textDecoration: removeUnderline ? '' : 'underline',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  color,
  zIndex: 1,
}))

const MainInfoLink = styled(StrapiLink)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
})

const TopBarInfoSlide = ({ data }) => {
  const { TextColor, Icon, Header, Title, DisableUnderline, InfoBarLink, SecondaryLink, Variant } = data
  const variant = Variant || 'IconAdjacent'
  const removeUnderline = !!DisableUnderline
  const textColor = TextColor?.ColorHex || '#ffffff'
  const hasIcon = !!Icon?.url

  return (
    <SlideContainer color={textColor} variant={variant}>
      {Icon?.url && (
        <img style={{ height: '50%' }} src={`${data.Icon.url}&fm=webp`} alt={`${Header || Title || ''} Icon`} />
      )}
      <InfoContainer variant={variant} hasIcon={hasIcon}>
        {Header && <StyledHeader color={textColor}>{Header}</StyledHeader>}
        {SecondaryLink?.Link && (
          <SecondaryLinkComponent
            removeUnderline={removeUnderline}
            color={textColor}
            data={SecondaryLink?.Link}
            disableUnderline
          />
        )}
        <MainInfoLink data={InfoBarLink?.Link} disableUnderline>
          <div style={{ display: 'none' }} />
        </MainInfoLink>
      </InfoContainer>
    </SlideContainer>
  )
}

TopBarInfoSlide.propTypes = {
  data: PropTypes.shape({
    TextColor: PropTypes.shape({
      ColorHex: PropTypes.string.isRequired,
    }).isRequired,
    Icon: PropTypes.shape({
      alternativeText: PropTypes.string,
      height: PropTypes.number,
      id: PropTypes.string,
      mime: PropTypes.string,
      url: PropTypes.string,
      width: PropTypes.number,
    }),
    Header: PropTypes.string,
    Title: PropTypes.string,
    DisableUnderline: PropTypes.bool,
    InfoBarLink: PropTypes.shape({
      Link: PropTypes.shape({
        AlternateDescription: PropTypes.string,
        Color: PropTypes.string,
        CustomLinkGrowthbookId: PropTypes.string,
        DisplayText: PropTypes.string,
        ExternalUrl: PropTypes.string,
        InternalUrl: PropTypes.string,
        MobileDisplayText: PropTypes.string,
        PredefinedComponentModal: PropTypes.string,
        PredefinedLink: PropTypes.string,
        ShowLearnMoreFinanceModal: PropTypes.string,
        Target: PropTypes.string,
        Title: PropTypes.string,
        id: PropTypes.string,
        testId: PropTypes.string,
      }),
    }),
    SecondaryLink: PropTypes.shape({
      Link: PropTypes.shape({
        AlternateDescription: PropTypes.string,
        Color: PropTypes.string,
        CustomLinkGrowthbookId: PropTypes.string,
        DisplayText: PropTypes.string,
        ExternalUrl: PropTypes.string,
        InternalUrl: PropTypes.string,
        MobileDisplayText: PropTypes.string,
        PredefinedComponentModal: PropTypes.string,
        PredefinedLink: PropTypes.string,
        ShowLearnMoreFinanceModal: PropTypes.string,
        Target: PropTypes.string,
        Title: PropTypes.string,
        id: PropTypes.string,
        testId: PropTypes.string,
      }),
    }),
    Variant: PropTypes.string,
  }).isRequired,
}

export default TopBarInfoSlide
