import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Segment } from '@components/integrations/Segment'
import { Fingerprint } from '@components/integrations/Fingerprint'
import LearnMoreAboutFinanceModal from '@components/shared/LearnMoreAboutFinanceModal'
import EmailUnsubscribeModal from '@components/shared/EmailUnsubscribeModal'
import { useStrapiContext } from '@context/strapiContext'
import Favicons from '../shared/favicons'
import BrowserDetect from '../browser-detect'
import LayoutChildren from './layout-children'
import '../../assets/css/global.sass'

export default function LAYOUT({ data, cartQuantity, isProductPage, className }) {
  const strapiContext = useStrapiContext()
  const segmentKey = process.env.GATSBY_SEGMENT_API_KEY
  let pathName = ''
  let showUnsubscribeModal = ''
  let opensFinanceLearnMoreModal = false

  if (typeof window !== 'undefined' && window.location) {
    pathName = window.location.pathname
  } else if (strapiContext?.page?.Route) {
    pathName = strapiContext.page.Route
  }

  // Check for query string in URL
  if (typeof window !== 'undefined' && window.location) {
    showUnsubscribeModal = new URL(window.location.href).searchParams.has('emailOptOut')
    opensFinanceLearnMoreModal = !!window.location?.hash?.includes?.('#fm')
    if (opensFinanceLearnMoreModal) {
      window.history?.replaceState?.({}, '', window.location?.href?.replace?.('#fm', ''))
    }
  } else {
    showUnsubscribeModal = ''
  }

  const getAlternatePathName = region => {
    const rootPaths = {
      pr: 'https://www.roomstogo.pr',
      us: 'https://www.roomstogo.com',
    }
    return rootPaths[region] + pathName
  }

  const metaInfo = [
    {
      name: 'google-site-verification',
      content: 'TGVtmWUFiuWkITFKGdw_cVZrhYlA-y3MIcMEhc-yDFw',
    },
    {
      name: 'google-site-verification',
      content: 'OWEZoxMRhdioV38iOk2ueJyCZSrsVUpUcho22mYuiRw',
    },
  ]

  if (!isProductPage) {
    metaInfo.unshift({
      name: 'description',
      content: 'Welcome to Rooms To Go!',
    })
  }

  // Disabling for early access blackfriday deals because they are not supported in the app
  // if (pathName === '/') {
  //   metaInfo.unshift({
  //     name: 'apple-itunes-app',
  //     content: 'app-id=898852501',
  //   })
  // }

  return (
    <div className={className}>
      {showUnsubscribeModal && <EmailUnsubscribeModal />}
      {segmentKey && <Segment apiKey={segmentKey} />}
      <Fingerprint />
      <a className="skip" href="#content">
        Skip to Main Content
      </a>
      <BrowserDetect />
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        link={[
          {
            rel: 'alternate',
            hrefLang: 'en-pr',
            href: getAlternatePathName('pr'),
          },
          {
            rel: 'alternate',
            hrefLang: 'en-us',
            href: getAlternatePathName('us'),
          },
        ]}
        meta={metaInfo}
        script={[
          {
            type: 'text/javascript',
            innerHTML: 'window.dataLayer = window.dataLayer || [];',
          },
        ]}
        bodyAttributes={{
          class: 'has-navbar-fixed-top',
        }}
      />
      <Favicons />
      <LayoutChildren data={data} cartQuantity={cartQuantity} />
      <LearnMoreAboutFinanceModal defaultOpened={opensFinanceLearnMoreModal} />
    </div>
  )
}

LAYOUT.propTypes = {
  data: PropTypes.object,
  cartQuantity: PropTypes.number,
  isProductPage: PropTypes.bool,
  className: PropTypes.string,
}
